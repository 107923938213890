import styled from 'styled-components'
import { tokens, spacing } from '@tokens'
import { H4Sizes, H6Sizes } from '@styles/Typography'

export const Author = styled.div`
  border-color: ${tokens.color('border')};
  border-style: solid;
  border-width: 2px 0;
  padding: ${spacing(4)} 0;
  margin: ${spacing(8)} 0 0;

  .inner {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  .image {
    flex-shrink: 0;
    width: 8.75rem;
    margin-right: ${spacing(4)};

    .gatsby-image-wrapper {
      border-radius: 100%;
    }
  }

  .heading {
    ${H4Sizes};
    margin-bottom: 0;
  }

  .subheading {
    ${H6Sizes};
    color: ${tokens.brand('darkAlt')};
    margin: 0 0 ${spacing(2)};
  }

  .bio {
    color: ${tokens.brand('darkAlt2')};
    font-size: 1rem;
  }
`