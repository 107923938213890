import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Container from '@components/Container'
import Hero from '@components/Hero'
import Author from '@partials/Author'
import Footer from '@components/Footer'
import { H6 } from '@styles/Typography'
import * as S from '@styles/components/Page'
import SEO from '@components/partials/SEO'
import { metaData } from '@fragments/meta'

export const BlogPostTemplate = ({
  hero,
  content,
  categories,
  tags,
  date,
  author,
  page,
}) => {
  hero.meta = {
    date,
    categories,
  }

  return (
    <Container page={page} heroType={hero.type}>
      <Hero {...hero} />
      <main>
        <S.PageContent>
          <section className="inner">
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            {tags && tags.length ? (
              <div className="tags">
                <H6>Tags:</H6>
                <ul className="taglist">
                  {tags.map(tag => (
                    <li key={`${tag.slug}tag`} className="tag">
                      <Link to={tag.path}>{tag.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {author && <Author teamMember={author} />}
          </section>
        </S.PageContent>
      </main>
      <Footer />
    </Container>
  )
}

BlogPostTemplate.propTypes = {
  hero: PropTypes.object,
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
}

const BlogPost = ({ data }) => {
  const {
    wordpressPost: { yoast_meta: meta, ...post },
    wordpressWpTeamMember: author,
  } = data
  const hero = {
    heading: post.title,
    sub_heading: post.acf.sub_heading,
    type: 'banner',
    image: post.acf.image,
  }

  return (
    <>
      <SEO {...meta} path={post.path} article={true} />
      <BlogPostTemplate
        hero={hero}
        content={post.content}
        categories={post.categories}
        tags={post.tags}
        title={post.title}
        date={post.date}
        author={author}
        page={post}
      />
    </>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

// TODO: Add author-based blog archives
export const pageQuery = graphql`
  # TODO: Use
  fragment PostFields on wordpress__POST {
    id
    slug
    path
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }
  query BlogPostByID($id: String!, $author: Int) {
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      path
      content
      date(formatString: "MMMM DD, YYYY")
      categories {
        name
        path
        slug
      }
      tags {
        name
        path
        slug
      }
      acf {
        image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        sub_heading
      }
      yoast_meta {
        ...metaData
      }
    }
    wordpressWpTeamMember(wordpress_id: { eq: $author }) {
      title
      acf {
        title
        bio
        headshot {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 640) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
