import React from 'react'
import Image from '@partials/Image'
import * as S from '@styles/components/Author'

export const Author = ({ teamMember }) => {
  const {
    title: name,
    acf: { headshot, title, bio },
  } = teamMember

  return (
    <S.Author>
      <div className="inner">
        <Image source={headshot} aspectRatio={1} />
        <div className="content">
          <h4 className="heading">{name}</h4>
          {title && <h5 className="subheading">{title}</h5>}
          {bio && (
            <div className="bio" dangerouslySetInnerHTML={{ __html: bio }} />
          )}
        </div>
      </div>
    </S.Author>
  )
}

export default Author
